// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-notes-index-js": () => import("./../../../src/pages/notes/index.js" /* webpackChunkName: "component---src-pages-notes-index-js" */),
  "component---src-pages-notes-recent-js": () => import("./../../../src/pages/notes/recent.js" /* webpackChunkName: "component---src-pages-notes-recent-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-testpage-js": () => import("./../../../src/pages/testpage.js" /* webpackChunkName: "component---src-pages-testpage-js" */),
  "component---src-templates-highlight-index-js": () => import("./../../../src/templates/highlight/index.js" /* webpackChunkName: "component---src-templates-highlight-index-js" */),
  "component---src-templates-highlight-list-tags-js": () => import("./../../../src/templates/highlight/listTags.js" /* webpackChunkName: "component---src-templates-highlight-list-tags-js" */),
  "component---src-templates-highlight-listing-js": () => import("./../../../src/templates/highlight/listing.js" /* webpackChunkName: "component---src-templates-highlight-listing-js" */),
  "component---src-templates-note-note-js": () => import("./../../../src/templates/note/note.js" /* webpackChunkName: "component---src-templates-note-note-js" */)
}

